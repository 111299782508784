import React from 'react'
import { Link } from 'react-router-dom'

import './BoxCards.css'

function BoxCards({ type, data }) {
    switch(type) {
        case 'bigCards':
            return (
                <>
                    <div className='section-size'>
                        <div className='page-size'>
                            <p>nothing at this moment</p>
                        </div>
                    </div>
                </>
            )

        case 'small':
            return (
                <>
                    <div className='section-size'>
                        <div className='page-size'>
                            <h2 className='section-title bottom-margin'>Servicii Oferite</h2>
                            <p className='section-subtitle bottom-margin'>Seriozitate, siguranta, confort, profesionalism, rapiditate</p>
                            <div className='grid-cards grid-three-cols'>
                                { data.map((item, i) => (
                                    <div key={i} className='small-card'>
                                        <div className='small-card--title'>
                                            <div className='icon--box'>
                                                <i className={`fa-solid ${item.icon} icon`}></i>
                                            </div>
                                            <h2 className='content--title'>{item.title}</h2>
                                        </div>
                                        <div className='small-card--content'>
                                            <p className='content--description'>{item.description}</p>
                                        </div>
                                        <div className='small-card--link'>
                                            <Link to={item.link} className='link'>{item.textLink}</Link>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </>
            )
        
        case 'horizontal':
            return (
                <>
                    <div className='section-size box-background'>
                        <div className='page-size'>
                            <h2 className='section-title bottom-margin'>Tipuri de Transport Oferite de Noi</h2>
                            <p className='section-subtitle'>Noi iti oferim o alternativa sigura si ieftina!</p>
                            <div className='box-cards'>
                                { data.map((item, i) => (
                                    <div key={i} className='box-card'>
                                        <div className='box-image'>
                                            <img className='image' src={item.url} alt={item.title} />
                                        </div>
                                        <div className='box-content'>
                                            <h2 className='card-title'>{item.title}</h2>
                                            <div className='card-subtitle'>{item.subtitle}</div>
                                            <div className='card-btn'>
                                                <Link to={item.link} className='card-link'>Vezi detalii</Link>
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </div>
                    </div>
                </>
            )

        default: return
    }
}

export default BoxCards