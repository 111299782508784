import React from 'react'
import './TopHeader.css'

function TopHeader() {
    return (
        <div className='contact-info'>
            <div className='contact-item'>
                <i className='fa-solid fa-phone contact-icon'></i>
                <div className='phone'>+40 733 837 771</div>
            </div>
            <div className='contact-item'>
                <i className='fa-solid fa-envelope contact-icon'></i>
                <div className='email'>transport@royaltrip.ro</div>
            </div>
        </div>
    )
}

export default TopHeader