import React from 'react'
import InnerBanner from '../components/common/InnerBanner/InnerBanner'
import BoxCards from '../components/elements/BoxCards/BoxCards'
import PageCredits from '../components/elements/PageCredits/PageCredits'
import SectionServices from '../components/elements/SectionServices/SectionServices'

function Transport() {
    const image = [{
            'url': '/assets/inner_banners/page_not_found.jpg',
            'title': 'Transport Royal Trip',
            'description': 'Agentia Royal Trip se adreseaza tuturor categoriilor sociale cu o gama diversificata de oferte tentante si accesibile.'
        }
    ]

    const boxes = [{
            'url': '/assets/hero_slider/slider_1.jpg',
            'title': 'Transport Litoral Romania',
            'subtitle': 'de la 75lei adult',
            'link': '/test/'
        }, {
            'url': '/assets/hero_slider/slider_2.jpg',
            'title': 'Transport Litoral Bulgaria Zona de Nord',
            'subtitle': 'de la 35€ dus/intors',
            'link': '/test/'
        }, {
            'url': '/assets/hero_slider/slider_3.jpg',
            'title': 'Transport Romania - Italia',
            'subtitle': 'de la 130€ adult',
            'link': '/test/'
        }, {
            'url': '/assets/hero_slider/slider_4.jpg',
            'title': 'Inchirieri Autocare & Microbuze',
            'subtitle': 'de la 0.90€/km',
            'link': '/test/'
        }
    ]

    return (
        <>
            <InnerBanner image={image}/>
            <BoxCards type={'horizontal'} data={boxes} />
            <SectionServices />
            <PageCredits />
        </>
    )
}

export default Transport