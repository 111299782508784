import React from 'react'

import HeroSlider from '../components/elements/HeroSlider/HeroSlider'
import BoxCards from '../components/elements/BoxCards/BoxCards'
import ContactUsBanner from '../components/elements/ContactUsBanner/ContactUsBanner'
import Timeline from '../components/elements/Timeline/Timeline'

function Home() {
    const slides = [{
            'url': '/assets/hero_slider/slider_1.jpg',
            'title': 'Inchiriere Autocare Turistice',
            'subtitle': 'Bucuresti & Ilfov',
            'description': 'Servicii de inchiriere autocare si transport la cele mai mici preturi de pe piata.',
            'hasBtn': true,
            'btnLink': '/transport/',
            'btnText': 'Citeste mai mult'
        }, {
            'url': '/assets/hero_slider/slider_2.jpg',
            'title': 'Transport Litoral Bulgaria',
            'description': 'Is this the third slider? Correct, it is.',
            'hasBtn': true,
            'btnLink': '/bulgaria/',
            'btnText': 'Vezi detalii'
        }
    ]

    const boxes = [{
            'icon': 'fa-person-walking-luggage',
            'title': 'Transport Excursii Scolare',
            'description': 'Doriti sa organizati o excursie cu clasa, fie in timpul saptamanii scoala altfel, fie la sfarsit de saptamana? Va asteptam sa apelati cu incredere la noi.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }, {
            'icon': 'fa-mountain-city',
            'title': 'Deplasari Team-Building',
            'description': 'Aveti nevoie de inchirierea unui autocar pentru un team-building cu firma sau pentru o excursie de grup? Oferim servicii de inchirieri autocare pentru astfel de evenimente.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }, {
            'icon': 'fa-plane',
            'title': 'Transfer Aeroport',
            'description': 'Va oferim transferuri in conditii ideale de confort, siguranta si punctualitate din Bucuresti si localitati limitrofe spre Aeroportul Otopeni.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }, {
            'icon': 'fa-person',
            'title': 'Transport Angajati',
            'description': 'Asiguram servicii de transport al angajatilor la si de la locul de munca, in condiţii de siguranta si confort, in Bucuresti, judetul Ilfov si in jurul Capitalei.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }, {
            'icon': 'fa-bus-simple',
            'title': 'Inchirieri Autocare',
            'description': 'Ofertele unice, personalizate si competitive, precum si investitia recenta in calitatea flotei auto reprezinta elementele cheie ale serviciilor noastre.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }, {
            'icon': 'fa-building',
            'title': 'Transport Evenimente',
            'description': 'Va invitam sa folositi serviciile noastre de transport pentru: excursii turistice, pelerinaje, conferinte, seminarii sau orice alt tip de transport pe orice destinatie.',
            'link': '/contact/',
            'textLink': 'Vezi mai mult'
        }
    ]

    const timelineData = [{
        'title': 'Contact Us',
        'description': 'Lorem ipsum sit amet, consectetur elit.'
    }, {
        'title': 'Consult',
        'description': 'Lorem ipsum sit amet, consectetur elit.'
    }, {
        'title': 'Place Order',
        'description': 'Lorem ipsum sit amet, consectetur elit.'
    }, {
        'title': 'Payment',
        'description': 'Lorem ipsum sit amet, consectetur elit.'
    }]

    return (
        <>
            <HeroSlider slides={slides} />
            <BoxCards type={'small'} data={boxes} />
            <Timeline data={timelineData} />
            <BoxCards type={'bigCards'} />
            <ContactUsBanner />
        </>
    )
}

export default Home