import React from 'react'
import { Link } from 'react-router-dom'

import './Timeline.css'

const banner = {
    backgroundImage: 'url("/assets/hero_slider/timeline_banner.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom center'
}

function Timeline({ data }) {
    return (
        <>
            <div className='section-size timeline-section' style={banner}>
                <div className='page-size timeline-section--inner'>
                    <div className='timeline-image--container'>
                        <div className='timeline-image'>
                            <img className='image' src='/assets/hero_slider/solicita_cotatie_pret.jpg' alt='Cele mai mici preturi' />
                        </div>
                    </div>
                    <div className='timeline-content'>
                        <h2 className='section-title'>Solicita cotatie de pret</h2>
                        <p className='description'>Pentru o cotatie de pret pentru inchirieri autocare si microbuze direct de la transportator, trebuie sa urmati pasii de mai jos.</p>

                        <div className='timeline-container'>
                            { data.map((item, i) => (
                                <div key={i} className='timeline-row'>
                                    <div className='step'>{i + 1}</div>
                                    <div className='body'>
                                        <h3 className='title'>{item.title}</h3>
                                        <p className='description'>{item.description}</p>
                                    </div>
                                </div>
                            )) }
                        </div>

                        <div className='timeline-btn'>
                            <Link to='/' className='button btn-fill'>Solicita oferta</Link>
                            <Link to='/' className='button btn-initial'>Solicita acum</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Timeline