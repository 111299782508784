import React, { useState, useEffect } from 'react'
// import LightGallery from 'lightgallery/react'

import './PageGallery.css'
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'
import 'lightgallery/css/lg-thumbnail.css'

function PageGallery({ images }) {
    const [firstImage, setFirstImage] = useState(0)
    const time = 8000

    const loadFirstImage = (i) => {
        setFirstImage(i)
    }

    const prevSlider = () => {
        const isFirstSlide = firstImage === 0
        const newIndex = isFirstSlide ? images.length - 1 : firstImage - 1
        setFirstImage(newIndex)
    }

    const nextSlider = () => {
        const isLastSlide = firstImage === images.length - 1
        const newIndex = isLastSlide ? 0 : firstImage + 1
        setFirstImage(newIndex)
    }

    useEffect(() => {
        const autoPlay = setInterval(nextSlider, time)
        return () => clearInterval(autoPlay)
    })

    return (
        <>
            <div className='page-gallery'>
                <div className='gallery-big-image'>
                    {/* <LightGallery
                        download={false}
                        share={false}>

                        <a href={images[firstImage].url}> */}
                            <div className='image-url'>
                                <img alt={images[firstImage].title} src={images[firstImage].url} className='gallery-first-image' />
                            </div>
                        {/* </a>                
                    </LightGallery> */}

                    <div className='gallery-controlls'>
                        <button className='gallery-arrow arrow-prev' onClick={prevSlider}><i className='fa-solid fa-angle-left'></i></button>
                        <button className='gallery-arrow arrow-next' onClick={nextSlider}><i className='fa-solid fa-angle-right'></i></button>
                    </div>
                </div>

                <div className='gallery-thumbs'>
                    { images.map((item, i) => (
                        <div key={i} className={firstImage === i ? 'thumb thumb-active' : 'thumb'} onClick={() => loadFirstImage(i)}>
                            <img alt={item.title} src={item.url} />
                        </div>
                    )) }
                </div>
            </div>
        </>
    )
}

export default PageGallery