import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import './HeroSlider.css'

function HeroSlider({ slides }) {
    const sliderBackground = {
        backgroundImage: 'url("/assets/hero_slider/green_background.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    }

    const [currIndex, setCurrIndex] = useState(0)
    const time = 8000

    const prevSlider = () => {
        const isFirstSlide = currIndex === 0
        const newIndex = isFirstSlide ? slides.length - 1 : currIndex - 1
        setCurrIndex(newIndex)
    }

    const nextSlider = () => {
        const isLastSlide = currIndex === slides.length - 1
        const newIndex = isLastSlide ? 0 : currIndex + 1
        setCurrIndex(newIndex)
    }

    useEffect(() => {
        const autoPlay = setInterval(nextSlider, time)
        return () => clearInterval(autoPlay)
    })

    return (
        <>
            <div className='slider-wrapper' style={sliderBackground}>
                <div className='slider-wrapper--inner'>
                    {slides.map((item, i) => (
                        <div key={i} className={currIndex === i ? 'slider-item active' : 'slider-item'}>
                            <div className='page-size slider-flex'>
                                <div className='slider-container'>
                                    <h1 className='slider-title'>{item.title}</h1>
                                    <h2 className='slider-subtitle'>{item.subtitle}</h2>
                                    <p className='slider-text'>{item.description}</p>
                                    <div className='slider-btn'>
                                        {item.hasBtn !== false ? (
                                            <Link to={item.btnLink} className='btn'>{item.btnText}</Link>
                                        ) : ''}
                                    </div>
                                </div>
                                <div className='slider-image'>
                                    <img className='image' src={item.url} alt={item.title} loading='lazy'/>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='page-size slider-controls'>
                        <button className='slider-arrow arrow-prev' onClick={prevSlider}><i className='fa-solid fa-angle-left'></i></button>
                        <button className='slider-arrow arrow-next' onClick={nextSlider}><i className='fa-solid fa-angle-right'></i></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSlider