import React from 'react'
import InnerBanner from '../components/common/InnerBanner/InnerBanner'

function PageNotFound() {
    const image = [{
            'url': '/assets/inner_banners/page_not_found.jpg',
            'title': 'Pagina Inexistenta'
        }
    ]

    return (
        <>
            <InnerBanner image={image}/>

            <div className='page-size page-not-found-wrapper'>
                <p className='page-not-found error'>Eroare 404!</p>
                <p className='page-not-found'>Pagina nu a fost gasita sau nu exista!</p>
            </div>
        </>
    )
}

export default PageNotFound