import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DatePicker, { registerLocale } from 'react-datepicker'
import ro from 'date-fns/locale/ro'
import config from '../../../config.json'

import './PageForm.css'
import 'react-datepicker/dist/react-datepicker.css'

function PageForm({ setTitle }) {
    registerLocale('ro', ro)

    const [dateRange, setDateRange] = useState([null, null])
    const [startDate, endDate] = dateRange

    const [checked, setChecked] = useState(false)

    const [formData, setFormData] = useState({
        pageTitle: setTitle[0].title,
        name: '',
        phone: '',
        email: '',
        transportType: 'Deplasari team-building', // default value for select
        message: ''
    })

    const [formError, setFormError] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        validation: {
            name: false,
            phone: false,
            email: false,
            message: false
        },
        sendMessage: '',
        sendColor: ''
    })

    // const isAvailableDay = (date) => {
    //     const day = date.getDay()
    //     return day >= 4 && day !== 5 
    // }

    const handleChange = (e) => {
        const nameReg = /^[a-zA-Z-ăâîșțĂÂÎȘȚ\s]+$/
        const phoneReg = /^[0-9]{3,16}$/
        const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        const messageReg = /^[a-zA-Z0-9-ăâîșțĂÂÎȘȚ()/,!?.;:+=@#$%^&*\s]+$/

        setFormData({ ...formData, [e.target.name]: e.target.value })

        if(e.target.name === 'name') {
            if(!nameReg.test(e.target.value) && e.target.value !== '') {
                setFormError({ ...formError, 
                    name: 'Numele si prenumele trebuie sa contina doar litere mari, litere mici, spatii si simbolul minus (-).',
                    validation: { name: false }
                })
            } else if(e.target.value === '') {
                setFormError({ ...formError, 
                    name: 'Numele si prenumele trebuie completate.',
                    validation: { name: false }
                })
            } else {
                setFormError({ ...formError, 
                    name: '',
                    validation: { name: true },
                    sendMessage: ''
                })
            }
        }

        if(e.target.name === 'phone') {
            if(e.target.value === '') {
                setFormError({ ...formError, 
                    phone: 'Numarul de telefon trebuie completat.',
                    validation: { phone: false }
                })
            } else if(e.target.value.length < 3 || e.target.value.length > 16 || (!phoneReg.test(e.target.value) && e.target.value !== '')) {
                setFormError({ ...formError, 
                    phone: 'Numarul de telefon trebuie sa contina intre 3 si 16 cifre.',
                    validation: { phone: false }
                })
            } else {
                setFormError({ ...formError, 
                    phone: '',
                    validation: { phone: true },
                    sendMessage: ''
                })
            }
        }

        if(e.target.name === 'email') {
            if(!emailReg.test(e.target.value) && e.target.value !== '') {
                setFormError({ ...formError, 
                    email: 'Adresa de email trebuie sa fie de forma something@domain.com',
                    validation: { email: false }
                })
            } else if(e.target.value === '') {
                setFormError({ ...formError, 
                    email: 'Adresa de email trebuie completata.',
                    validation: { email: false }
                })
            } else {
                setFormError({ ...formError, 
                    email: '',
                    validation: { email: true },
                    sendMessage: ''
                })
            }
        }

        if(e.target.name === 'message') {
            if(!messageReg.test(e.target.value) && e.target.value !== '') {
                setFormError({ ...formError, 
                    message: 'Mesajul contine caractere speciale nepermise.',
                    validation: { message: false }
                })
            } else {
                setFormError({ ...formError, 
                    message: '',
                    validation: { message: true },
                    sendMessage: ''
                })
            }
        }
    }

    const sendEmail = (e) => {
        e.preventDefault()

        if(formError.validation.name === false || formError.validation.phone === false
        || formError.validation.email === false || formError.validation.message === false) {
            return setFormError({ ...formError, sendMessage: 'Unul sau mai multe campuri nu sunt completate corect.', sendColor: 'msg-red' })
        }

        if(checked === false) {
            return setFormError({ ...formError, sendMessage: 'Trebuie acceptati Termenii si Conditiile si Politica de Confidentialitate.', sendColor: 'msg-red' })
        }

        axios.post(`${config.development_url}/${config.endpoints.requestForm}`, {
            pageTitle: formData.pageTitle,
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            startDate: startDate,
            endDate: endDate,
            transportType: formData.transportType,
            message: formData.message
        })
        
        // Send mail successfully
        setFormData({ pageTitle: setTitle[0].title, name: '', phone: '', email: '', transportType: '', message: '' })
        setDateRange([null, null])
        setFormError({ ...formError,  sendMessage: 'Va multumim! Mesajul a fost trimis cu succes', sendColor: 'msg-green' })
        setChecked(false)
    }

    return (
        <>
            <div className='page-form'>
                <form onSubmit={sendEmail}>
                    <div className='first-line-set line-set'></div>
                    <div className='second-line-set line-set'></div>
                    <h3 className='form-title'>Formular de Rezervare</h3>
                    <div className='form-row'>
                        <label className='form-label'><span className='label-star'>*</span> Nume & Prenume</label>
                        <input type='text' className='form-field' name='name' value={formData.name} onChange={handleChange} required />
                        { formError.name && <p className='field-error'>{formError.name}</p> }
                    </div>
                    <div className='form-row'>
                        <label className='form-label'><span className='label-star'>*</span> Telefon</label>
                        <input type='tel' className='form-field' name='phone' value={formData.phone} onChange={handleChange} required />
                        { formError.phone && <p className='field-error'>{formError.phone}</p> }
                    </div>
                    <div className='form-row'>
                        <label className='form-label'><span className='label-star'>*</span> Adresa Email</label>
                        <input type='email' className='form-field' name='email' value={formData.email} onChange={handleChange} required />
                        { formError.email && <p className='field-error'>{formError.email}</p> }
                    </div>
                    <div className='form-row'>
                        <label className='form-label'><span className='label-star'>*</span> Perioada</label>
                        <DatePicker
                            selectsRange={true}
                            placeholderText='Data Plecare - Data Sosire'
                            dateFormat='dd.MM.yyyy'
                            startDate={startDate}
                            endDate={endDate}
                            monthsShown={2}
                            focusSelectedMonth={true}
                            locale='ro'
                            name='transportDate'
                            // filterDate={isAvailableDay}
                            onChange={(update) => {
                                setDateRange(update)
                            }}
                            className='form-field cursor-field custom-date'
                            isClearable={true}
                            required
                        />
                    </div>
                    <div className='form-row'>
                        <label className='form-label'><span className='label-star'>*</span> Tipul de Transport</label>
                        <select className='form-field cursor-field' name='transportType' value={formData.transportType} onChange={handleChange} required>
                            <option value='Deplasari team-building'>Deplasari team-building</option>
                            <option value='Transferuri aeroport hotel'>Transferuri aeroport hotel</option>
                            <option value='Transport excursii elevi'>Transport excursii elevi</option>
                            <option value='Transport pelerinaje'>Transport pelerinaje</option>
                            <option value='Transport conferinte, evenimente'>Transport conferinte, evenimente</option>
                            <option value='Transport angajati la locul de munca'>Transport angajati la locul de munca</option>
                        </select>
                    </div>
                    <div className='form-row'>
                        <label className='form-label'>Mesaj</label>
                        <textarea className='form-field text-field' name='message' value={formData.message} onChange={handleChange}></textarea>
                        { formError.message && <p className='field-error'>{formError.message}</p> }
                    </div>
                    <div className='form-row'>
                        <label className="custom-check"><span className='label-star'>*</span> Am citit si sunt de acord cu <Link to='/' className='custom-link'>Politica de Confidentialitate</Link> si <Link to='/' className='custom-link'>Termenii si Conditiile</Link>.
                            <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    { formError.sendMessage &&
                        <div className='form-row'>
                            <div className={'msg-background ' + formError.sendColor}>
                                <p className='msg-text'>{formError.sendMessage}</p>
                            </div>
                        </div>
                    }
                    <div className='form-row'>
                        <input type='submit' className='form-submit cursor-field' value='Trimite' />
                    </div>
                </form>
            </div>
        </>
    )
}

export default PageForm