import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Data } from './Data'

import './NavLinks.css'

function NavLinks() {
    const [openMenu, setOpenMenu] = useState(false)

    // used to open the dropdown for a certain main nav link pressed
    const openOnClick = (i) => {
        if(openMenu === i) {
            setOpenMenu(false)
        } else {
            setOpenMenu(i)
        }
    }

    // used to move the underline for the text accordingly
    const activeStateToParent = (e) => {
        // fixed a issue for underline for the text when route is changed
        if(e.target.closest('.hasLevel')) {
            setTimeout(() => {
                e.target.closest('.hasLevel').firstChild.classList.add('active')
            }, 0)
        }

        // getting all nav items
        const siblings = document.getElementsByClassName('nav-item')
        // getting the target's index
        const targetIndex = e.target.closest('.nav-item').firstChild.getAttribute('data-index')

        for(var i = 0; i < siblings.length; i++) {
            // getting every index step by step
            const siblingIndex = siblings[i].firstChild.getAttribute('data-index')
            // we check if the step index is different than the target parent element index
            if(siblingIndex !== targetIndex) {
                siblings[i].firstChild.classList.remove('active')
            }
        }
    }

    // used to keep the underline for the text after page refresh
    useEffect(() => {
        window.addEventListener('load', () => {
            const navItems = document.querySelectorAll('.level1 > .nav-item')
            const navElemChild = document.querySelector('.level2 .nav-item .link.active')
            
            for(var i = 0; i < navItems.length; i++) {
                if(navItems[i].classList.contains('hasLevel')) {
                    navElemChild.closest('.hasLevel').firstChild.classList.add('active')
                }
            } 
        })
    })

    return (
        <>
            <ul className='nav-links level1'>
                {Data.map((item, i) =>
                    <li key={i} className={item.hasLevel ? 'nav-item hasLevel' : 'nav-item'}>
                        {item.pagePathNull === false ? (
                            <button className={`link ${openMenu === i ? 'active' : ''}`} onClick={() => openOnClick(i)} data-index={i}>{item.pageName}</button>
                        ) : (
                            <NavLink className='link' to={item.pagePath} onClick={(e) => {
                                openOnClick(i);
                                activeStateToParent(e);
                            }} data-index={i}>{item.pageName}</NavLink>
                        )}
                        {item.hasLevel && (
                            <ul className={`${item.hasLevel} ${openMenu === i ? "open-menu" : ""}`}>
                                {item.pageSubmenu && Object.values(item.pageSubmenu).map((subItem, j) =>
                                    <li key={j} className='nav-item'>
                                        <NavLink className='link' to={subItem.pagePath} onClick={(e) => {
                                            openOnClick(i);
                                            activeStateToParent(e);
                                        }}>{subItem.pageName}</NavLink>
                                    </li>
                                )}
                            </ul>
                        )}
                    </li>
                )}
            </ul>
        </>
    )
}

export default NavLinks