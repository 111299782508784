import React, { useState } from 'react'

import './Accordion.css'

function Accordion({ accordionProps }) {
    const [openTab, setOpenTab] = useState(false)

    const openOnClick = (i) => {
        if(openTab === i) {
            setOpenTab(false)
        } else {
            setOpenTab(i)
        }
    }

    return (
        <>
            {accordionProps.map((item, i) => (
                <div key={i} className='accordion-item'>
                    <div className={`accordion-head ${openTab === i ? 'active' : ''}`} onClick={() => openOnClick(i)}>
                        <i className={`fa-solid ${openTab === i ? 'fa-minus' : 'fa-plus'} accordion-icon`}></i>
                        {item.title}
                    </div>
                    <div className={`accordion-body ${openTab === i ? 'active' : '' }`}>
                        <div className='accordion-inner-body'>
                            {Object.values(item.content).map((item, i) => (
                                <p key={i} className='accordion-paragraph'><b>»</b> {item}</p>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Accordion