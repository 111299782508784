import React from 'react'

import './PageCredits.css'

function PageCredits() {
    return (
        <>
            <div className='section-size background--light-gray'>
                <div className='page-size'>
                    <div className='page-credits'>
                        <h3 className='page-title'>Transportator Royal Trip</h3>
                        <img className='page-logo' src='/assets/logo/royaltrip_logo_text.svg' alt='Agentia Royal Trip' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageCredits