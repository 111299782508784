import React from 'react'
import InnerBanner from '../components/common/InnerBanner/InnerBanner'

function Contact() {
    const image = [{
            'url': '/assets/inner_banners/contact_banner.jpg',
            'title': 'Contact',
            'description': 'Contact inchirieri autocare | Contact agentie de transport persoane'
        }
    ]

    const iframeStyle = {
        width: '100%',
        height: '450px',
        border: '0'
    }

    return (
        <>
            <InnerBanner image={image}/>
            <iframe style={iframeStyle} title='Locatie Royal Trip' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5699.925726927066!2d26.10349830917531!3d44.413407769568096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8cbc1dc39b689417!2sInchirieri%20autocare!5e0!3m2!1sro!2sro!4v1614708390966!5m2!1sro!2sro' allowFullScreen='allowfullscreen'></iframe>
            <div className='section-size'>
                <div className='page-size'>
                </div>
            </div>
        </>
    )
}

export default Contact