import React, { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Import CSS
import './App.css'

// Import Common Components
import ScrollToTop from './ScrollToTop'
import Header from './components/common/Header/Header'
import Footer from './components/common/Footer/Footer'

// Import Pages
import Home from './pages/Home'
import PageNotFound from './pages/PageNotFound'

import Bus47Seats from './pages/buses/Bus47Seats'
import Bus49Seats from "./pages/buses/Bus49Seats"
import Bus53Seats from "./pages/buses/Bus53Seats"
import Bus55Seats from "./pages/buses/Bus55Seats"
import Bus57Seats from "./pages/buses/Bus57Seats"

import Contact from './pages/Contact'
import Transport from "./pages/Transport"

function App() {
	useEffect(() => {
		window.addEventListener('scroll', isSticky)

		return () => {
			window.removeEventListener('scroll', isSticky)
		}
	})

	const isSticky = () => {
		const header = document.querySelector('header')
		const scrollTop = window.scrollY

		scrollTop > 1 ? header.classList.add('sticky-header') : header.classList.remove('sticky-header')
	}

	return (
		<>
			<Router>
				<header>
					<ScrollToTop />
					<Header />
				</header>

				<main>
					<Routes>
						{/* Landing Page */}
						<Route exact path='/' element={ <Home /> } />
						{/* Bus Pages */}
						<Route path='/autocar-47-locuri/' element={ <Bus47Seats /> } />
						<Route path='/autocar-49-2-locuri/' element={ <Bus49Seats /> } />
						<Route path='/autocar-53-locuri/' element={ <Bus53Seats /> } />
						<Route path='/autocar-55-locuri/' element={ <Bus55Seats /> } />
						<Route path='/autocar-57-locuri/' element={ <Bus57Seats /> } />
						{/* Generic Pages */}
						<Route path='/transport/' element={ <Transport /> } />
						<Route path='/contact/' element={ <Contact /> } />
						<Route path='*' element={ <PageNotFound /> }/>
					</Routes>
				</main>

				<footer>
					<Footer />
				</footer>
			</Router>
		</>
	)
}

export default App