import React from 'react'

import './InnerBanner.css'

function InnerBanner({ image }) {
    return (
        <>
            <div className='heading-section'>
                <img src={image[0].url} alt={image[0].title} />
                <div className='heading-content'>
                    <h1 className='heading-title'>{image[0].title}</h1>
                    {image[0].description && (<p className='heading-description'>{image[0].description}</p>)}
                </div>
            </div>
        </>
    )
}

export default InnerBanner