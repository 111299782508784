import React from 'react'
import InnerBanner from '../../components/common/InnerBanner/InnerBanner'
import PageForm from '../../components/elements/PageForm/PageForm'
import PageGallery from '../../components/elements/PageGallery/PageGallery'

function Bus53Seats() {
    const image = [{
            'url': '/assets/inner_banners/page_not_found.jpg',
            'title': 'Autocar 53 locuri',
            'description': 'Inchirieri autocare 53 + 2 locuri'
        }
    ]

    const gallery = [{
        'title': 'Prima Imagine',
        'url': '/assets/cars/53seats/autocar_man.jpg'
    }, {
        'title': 'A doua Imagine',
        'url': '/assets/cars/53seats/autocar_man_2.jpg'
    }, {
        'title': 'A treia Imagine',
        'url': '/assets/cars/53seats/autocar_man_3.jpg'
    }, {
        'title': 'A treia Imagine',
        'url': '/assets/cars/53seats/autocar_man_4.jpg'
    }]

    return (
        <>
            <InnerBanner image={image}/>
            <div className='section-size'>
                <div className='page-size page--flex'>
                    <div className='page-content'>
                        <PageGallery images={gallery} />

                        <p>Autocarul MAN Noge are o capacitate de 53 locuri, fiind destinat grupurilor de turisti sau evenimentelor de orice fel. Autocarul MAN Noge este foarte curat si bine intretinut. Inchirieri autocare Bucuresti la cel mai bun raport calitate / pret.</p>

                        <div className='list--flex'>
                            <div className='page-list'>
                                <p className='list-title'>Autocarul are urmatoarele dotari:</p>
                                <ul className='list'>
                                    <li>iluminare individuala</li>
                                    <li>aer conditionat</li>
                                    <li>webasto</li>
                                    <li>cala bagaje</li>
                                    <li>sistem audio / video</li>
                                    <li>statie cu microfon</li>
                                    <li>2 monitoare</li>
                                    <li>scaune rabatabile</li>
                                    <li>cotiere scaune</li>
                                    <li>tapiterie cu material textil</li>
                                    <li>geamuri panoramice</li>
                                    <li>centuri de siguranta</li>
                                    <li>calorifere electrice</li>
                                    <li>wc</li>
                                </ul>
                            </div>
                            <div className='page-list'>
                                <h2 className='list-title'>Inchiriere autocar 53 + 2 locuri:</h2>
                                <ul className='list'>
                                    <li>1 / 2 soferi atestati</li>
                                    <li>combustibil</li>
                                    <li>RCA, carte verde</li>
                                    <li>asigurare calatori si bagaje</li>
                                    <li>servicii de condus</li>
                                </ul>
                            </div>
                        </div>

                        <p>Beneficiarul trebuie sa asigure cazarea si masa soferului (soferilor) cat si taxele de parcari, poduri, tuneluri, autostrazi, feribot-uri etc.</p>
                        <p>Tarifele pot varia in cazul unor contracte pe termen lung sau in functie de numarul de kilometri parcursi, numarul de zile la dispozitie, taxe aferente traseului dumneavoastra, numarul de soferi, perioada calendaristica aleasa pentru derularea actiunii, preturile combustibililor pentru tarile tranzitate etc.</p>
                    </div>
                    <div className='page-form'>
                        <PageForm setTitle={image}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bus53Seats