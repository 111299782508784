import React from 'react'
import { Link } from 'react-router-dom'

import './Footer.css'

function Footer() {
	return (
		<>
			<div className='section-size'>
				<div className='page-size'>
					<div className='footer-grid'>
						<div className='footer-col'>
							<h3 className='footer-title'>Contact Info</h3>
							<div className='contact-group'>
								<div className='contact-item'>
									<i className='fa-solid fa-mobile-screen'></i>
									<span className='contact-text'>+40 767 345 090</span>
								</div>
								<div className='contact-item'>
									<i className='fa-solid fa-mobile-screen'></i>
									<span className='contact-text'>+40 733 837 771</span>
								</div>
							</div>
							<div className='contact-group'>
								<div className='contact-item'>
									<i className='fa-solid fa-envelope'></i>
									<span className='contact-text'>transport@royaltrip.ro</span>
								</div>
								<div className='contact-item'>
									<i className='fa-solid fa-envelope'></i>
									<span className='contact-text'>office.inchiriereautocare@gmail.com</span>
								</div>
							</div>
							<div className='contact-links'>
								<a href='https://anpc.ro/' className='contact-link' target='_blank' rel='noreferrer'><i className='fa-solid fa-link'></i> ANPC</a>
								<Link to='/'className='contact-link' ><i className='fa-solid fa-link'></i> Termeni si Conditii</Link>
								<Link to='/'className='contact-link' ><i className='fa-solid fa-link'></i> Politica de Confidentialitate</Link>
							</div>
						</div>
						<div className='footer-col'>
							<h3 className='footer-title'>Despre Noi</h3>
							<p className='footer-text'>Profesionalismul este cheia succesului unui transport reusit ca pret, rapiditate si comoditate. Membru acreditat, va punem la dispozitie autocare si microbuze, clasificate conform normelor in vigoare pentru calatoriile dumneavoastra. in tara si strainatate.</p>
							<div className='contact-links'>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Angajari</Link>
								<a href='https://royaltrip.ro/blog/' className='contact-link' target='_blank' rel='noreferrer'><i className='fa-solid fa-link'></i> Blog Royal Trip</a>
							</div>
						</div>
						<div className='footer-col'>
							<h3 className='footer-title'>Ce Oferim</h3>
							<div className='contact-links'>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Transport Romania - Italia</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Charter Litoral Bulgaria</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Transport Persoane</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Transport Angajati</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Inchirieri Autocare</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Inchirieri Microbuze</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Inchiriere 8+1 Locuri</Link>
								<Link to='/' className='contact-link'><i className='fa-solid fa-link'></i> Transport Scolar</Link>
							</div>
						</div>
						<div className='footer-col'>
							<h3 className='footer-title'>Inchirieri Autocare</h3>
							{/* <iframe title='Inchirieri Autocare Facebook' className='iframe-footer' src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Finchirieri.autocare.turistice&tabs=timeline&width=300&height=290&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId=637937042919498' scrolling='no' frameBorder='0' allowFullScreen allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'></iframe> */}

							<a href='https://bucuresti.cylex.ro/firma/autocare+de+inchiriat+-+royal+trip-632239.html' target='_blank' rel='noreferrer'>
								<img className='footer-promo-img' title="Autocare de Inchiriat - Royal Trip" src='/assets/cylex_logo.png' alt='CYLEX' />
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer