import React from 'react'
import { Link } from 'react-router-dom'

import TopHeader from '../TopHeader/TopHeader'
import NavLinks from './NavLinks/NavLinks'

import './Header.css'

function Header() {
    const activeStateOnHome = () => {
        // getting all nav items
        const siblings = document.getElementsByClassName('nav-item')

        for(var i = 0; i < siblings.length; i++) {
            // remove active class from all elements that have
            siblings[i].firstChild.classList.remove('active')
        }
    }

    return (
        <>
            <div className="page-size">
                <div className='header-section'>
                    <div className='nav-logo' onClick={activeStateOnHome}>
                        <Link to='/' className='logo-white'>
                            <img src='/assets/logo/logo_header_white.png' alt='Royal Trip Logo' />
                        </Link>
                        
                        <Link to='/' className='logo-color'>
                            <img src='/assets/logo/logo_header.png' alt='Royal Trip Logo' />
                        </Link>
                    </div>
                    <nav className='nav-menu'>
                        <TopHeader />
                        <NavLinks />
                    </nav>
                </div>
            </div>
        </>
    )
}

export default Header