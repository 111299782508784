import React from 'react'
import { Link } from 'react-router-dom'
import Accordion from '../Accordion/Accordion'

function SectionServices() {
    // const firstColumn = [{
    //     'title': 'Transport Excursii Scolare',
    //     'content': [
    //         'Doriți să organizați o excursie cu clasa, fie în timpul Săptămânii Școala Altfel, fie la sfârșit de săptămână, vă asteptam să apelați cu încredere la noi.',
    //         'Agentia Royal Trip detine mijloace de transport ideale pentru organizarea de excursii elevi. Toate autocarele si microbuzele firmei se inscriu in prevederile legale care reglementeaza efectuarea de transporturi de elevi pentru activitati specific educationale, respectiv manifestari culturale, competitii sportive, excursii si vizite de studiu care se desfasoara in cadrul unor parteneriate scolare sau extrascolare.',
    //         'Cu noi, plecati la drum in siguranta!',
    //         'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
    //         'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
    //     ]
    // }, {
    //     'title': 'Deplasari Team-Building',
    //     'content': [
    //         'Aveti nevoie de inchirierea unui autocar pentru un teambuilding cu firma sau pentru o excursie de grup?',
    //         'Oferim servicii de inchirieri autocare pentru astfel de evenimente.',
    //         'Reprezentati o companie si vrei transport pentru un eveniment teambuilding ? Va putem ajuta.',
    //         'Oferim servicii de inchirieri autocare, la cele mai atractive preturi.',
    //         'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
    //         'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
    //     ]
    // }, {
    //     'title': 'Transfer Aeroport',
    //     'content': [
    //         'Va oferim transferuri in conditii ideale de confort, siguranta si punctualitate din Bucuresti si localitati limitrofe spre Aeroportul Otopeni.',
    //         'De asemenea, asigurăm transferul de la Aeroportul Otopeni catre orice destinatie dorita.',
    //         'La orice ora din zi sau din noapte poti comanda un autocar la aeroport. Tot ce trebuie sa faceti este sa ne sunati sau sa rezervati online in prealabil pentru a ne comunica detaliile de zbor. Astfel sunteti siguri ca veti gasi la poarta de iesire unul dintre soferii nostri care va va insoti la masina si de acolo spre locul in care vrei sa duci grupul in cele mai bune conditii.',
    //         'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
    //         'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
    //     ]
    // }, {
    //     'title': 'Transport Angajati',
    //     'content': [
    //         'Aveti un numar mare de salariati si doriti sa fiti siguri ca ajung la locul de munca la ora stabilita?',
    //         'Noi suntem solutia ideala! Asigurăm servicii de transport al angajaţilor la şi de la locul de muncă, în condiţii de siguranţă şi confort, în Bucureşti, județul Ilfov și în jurul Capitalei.',
    //         'Închiriem autocare cu șofer în regim de convenție / contract, pentru transportul angajaților, salariaților la și de la locul de muncă.',
    //         'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
    //         'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
    //     ]
    // }, {
    //     'title': 'Inchirieri Autocare',
    //     'content': [
    //         'Ofertele unice, personalizate si competitive, precum si investitia recenta in calitatea flotei auto reprezinta elementele cheie ale serviciilor noastre.',
    //         'Autocarele se inchiriaza cu sofer profesionist atestat si asigurare la zi.',
    //         'Solicitati o oferta de pret pentru inchiriere autocare Bucuresti si Ilfov. Suntem deschisi la orice propunere in domeniul transporturilor asa ca, nu ezitati sa ne spuneti cat mai multe detalii.',
    //         'Veti avea deplasari si excursii reusite din toate punctele de vedere.',
    //     ]
    // }]

    const secondColumn = [{
        'title': 'Transport Persoane',
        'content': [
            'Societatea de transport persoane Royal Trip Bucuresti, pune mare pret pe tot ce inseamna calitate, de aceea toate autovehiculele aflate in parcul nostru auto sunt dotate si licentiate conform normelor europene iar acest lucru, impreuna cu serviciile oferite de personalul nostru calificat fac din societatea noastra una dintre cele mai bine cotate firme de transport persoane.',
            'Daca va doriti servicii de calitate, servicii de care sa va amintiti cu drag, noi sunt aici pentru a face acest lucru.',
            'Suntem aici pentru a indeplinii orice nevoie in materie de transport persoane!',
            'Siguranta calatorilor ramane prioritatea noastra!',
        ]
    }, {
        'title': 'Inchirieri Microbuze',
        'content': [
            'Aveti o intalnire de afaceri in afara localitatii, doriti sa participati la un eveniment special si vreti ca totul sa decurca perfect, doriti sa mergeti intr-un team-building cu colegii dumneavoastra?',
            'Daca va regasiti in situatiile de mai sus cu siguranta va putem face viata mai usoara cu serviciile de inchirieri microbuze din Bucuresti.',
            'Cu siguranta vei gasi pretul care iti convine!'
        ]
    }, {
        'title': 'Inchirieri Mini-Van',
        'content': [
            'Sofatul reprezinta o adevarata pasiune pentru multi dintre dvs insa trebuie sa recunoastem ca nu de putine ori suntem pusi in situatia de a ne detasa total de aceasta activitate, drept dovada numeroasele situatii in care avem nevoie de un sofer profesionist.',
            'Societatea noastra va ofera spre inchiriere microbuze cu o capacitate de transport de 9 locuri pe care le puteti inchiria atat cu sofer.',
            'Inchiriaza un microbuz de 8+1 locuri si vei putea calatori linistit.'
        ]
    }, {
        'title': 'Servicii de Transport Persoane',
        'content': [
            'Societatea Royal Trip va pune la dispozitie serviciul transport persoane pentru orice ruta din Romania sau din afara tarii. Impreuna cu soferi profesionisti. Calatoria dumneavoastra se va initia in cele mai bune conditii catre orice destinatie. Pentru transportul intern si international de persoane va oferim microbuze si autocare cu capacitate incepand de la: 8+1 – 19 locuri si pana la 55 locuri.',
            'Misiunea noastra este sa ne ducem calatorii confortabil si in siguranta la destinatie. Ne-am impus standarde de siguranta si confort ridicate pentru a satisface pe cei mai multi din clientii nostri.',
            'Noi facem calatoria dumneavoastra mai usoara si mai confortabila! Daca trebuie sa ajungeti undeva, cu noi veti ajunge 100% sigur!',
            'Apelati la serviciile noastre si nu veti fi dezamagiti, veti fi incredintati ca noi suntem cea mai buna alegere!',
            'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
            'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
        ]
    }, {
        'title': 'Autocare de Inchiriat',
        'content': [
            'Royal Trip este o agentie, care si-a propus sa va ofere doar servicii turistice de cea mai buna calitate, incluzand si servicii inchiriere autocare. Societatea Royal Trip te invita sa calatoresti alaturi de noi oriunde doresti si iti pune la dispozitie oricand o gama variata de excursii, circuite, sejururi cat si organizari de pelerinaje interne si externe.',
            'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
            'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
        ]
    }/*, {
        'title': 'Transport Conferinte-Evenimente',
        'content': [
            'Cu o vasta experienta in transportul de grupuri la diferite tipuri de evenimente, va invitam sa folositi serviciile noastre de transport pentru: excursii turistice, pelerinaje, deplasari sportive, conferinte, seminarii, pelerinaje, team-building-uri, transport angajati sau orice alt tip de transport pe orice destinatie.',
            'Echipa Royal Trip va sta la dispozitie in orice moment cu cel mai bun pret si cu o flota de autocare, foarte spatioase pentru confortul garantat.',
            'Toate auvehiculele sunt licentiate, clasificate si au asigurari de persoane / bagaje cu soferi atestati si experimentati.',
            'Servicii de inchiriere autocare la cel mai bun pret de pe piata intern si international.',
            'Cauti calitate, confort, siguranta, seriozitate si profesionalism… Pe toate le găsești la S.C. ROYAL TRIP SRL',
            'Echipa noastră vă pune la dispoziție toate metodele de a lua contact cu noi: email, telefon, social media, formulare online.'
        ]
    }*/]

    return (
        <>
            <div className='section-size background--light-gray'>
                <div className='page-size'>
                    <h2 className='section-title bottom-margin'>Servicii Oferite</h2>
                    <p className='section-subtitle bottom-margin'>Seriozitate, siguranta, confort, profesionalism, rapiditate</p>

                    <div className='accordion-grid'>
                        {/* <div className='accordion-row'>
                            <Accordion accordionProps={firstColumn}/>
                        </div> */}
                        <div className='accordion-row'>
                            <Accordion accordionProps={secondColumn}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionServices