export const Data = [{
        pageName: 'Home',
        pagePath: '/'
    }, {
        pageName: 'Inchirieri Autocare',
        pagePath: '/autocare/',
        pagePathNull: false,
        hasLevel: 'level2',
        pageSubmenu: [{
                pageName: 'Autocar 47 Locuri',
                pagePath: 'autocar-47-locuri/'
            }, {
                pageName: 'Autocar 49 Locuri',
                pagePath: 'autocar-49-2-locuri/'
            }, {
                pageName: 'Autocar 53 Locuri',
                pagePath: 'autocar-53-locuri/'
            }, {
                pageName: 'Autocar 55 Locuri',
                pagePath: 'autocar-55-locuri/'
            }, {
                pageName: 'Autocar 57 Locuri',
                pagePath: 'autocar-57-locuri/'
            }
        ]
    }, {
        pageName: 'Inchirieri Microbuze',
        pagePath: '/microbuze/',
        pagePathNull: false,
        hasLevel: 'level2',
        pageSubmenu: [{
                pageName: 'Van 7 + 1 Locuri',
                pagePath: 'van-7-1-locuri/'
            }, {
                pageName: 'Microbuz 16 + 1 Locuri',
                pagePath: 'microbuz-16-1-locuri/'
            }, {
                pageName: 'Microbuz 19 + 1 Locuri',
                pagePath: 'microbuz-19-1-locuri/'
            }
        ]
    }, {
        pageName: 'Transport',
        pagePath: '/transport/',
        pagePathNull: false,
        hasLevel: 'level2',
        pageSubmenu: [{
                pageName: 'Tipuri de Transport',
                pagePath: 'transport/'
            }, {
                pageName: 'Charter Litoral Romania',
                pagePath: 'litoral-romania/'
            }, {
                pageName: 'Charter Litoral Bulgaria',
                pagePath: 'transport-litoral-bulgaria/'
            }, {
                pageName: 'Transport Romania – Italia',
                pagePath: 'transport-romania-italia/'
            }
        ]
    }, {
        pageName: 'Contact',
        pagePath: '/contact/'
    }
]