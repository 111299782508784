import React from 'react'
import { Link } from 'react-router-dom'

import './ContactUsBanner.css'

function ContactUsBanner() {
    return (
        <div className='page-size'>
            <div className='section-contactus background--dark-green'>
                <div className='contactus--inner'>
                    <div className='contactus--content'>
                        <h2 className='title bottom-margin'>Calatoreste in Siguranta!</h2>
                        <p className='description'>Profesionalismul este cheia succesului unui transport reusit ca pret, 
                        rapiditate si comoditate. Membru acreditat, va punem la dispozitie autocare si microbuze, licentiate 
                        conform normelor in vigoare pentru calatoriile dumneavoastra in tara si strainatate.</p>
                    </div>
                    <div className='contactus--btn'>
                        <Link to='/contact/' className='custom-btn'>Contactati-ne!</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUsBanner